exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-fields-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.fields__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-fields-slug-tsx" */),
  "component---src-pages-words-archive-tsx": () => import("./../../../src/pages/words/archive.tsx" /* webpackChunkName: "component---src-pages-words-archive-tsx" */),
  "component---src-pages-words-index-tsx": () => import("./../../../src/pages/words/index.tsx" /* webpackChunkName: "component---src-pages-words-index-tsx" */),
  "component---src-pages-work-archive-tsx": () => import("./../../../src/pages/work/archive.tsx" /* webpackChunkName: "component---src-pages-work-archive-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */)
}

